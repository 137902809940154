<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <validation-observer ref="eventRules">
              <b-form>
                <b-row> 
                  <b-col cols="12">
                    <b-form-group label="Event Name" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="Event Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="data.name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Event Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Start Date" label-for="start_date">
                      <validation-provider
                        #default="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="data.start_date"
                          class="form-control"
                          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="End Date" label-for="end_date">
                      <validation-provider
                        #default="{ errors }"
                        name="End Date"
                        rules="required"
                      >
                        <flat-pickr
                          v-model="data.end_date"
                          class="form-control"
                          :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <quill-editor
                          v-model="data.description"
                          :options="showOptionEditor"
                          :state="errors.length > 0 ? false:null"
                          style="min-height: 200px;"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Created By" label-for="created_by">
                      <validation-provider
                        #default="{ errors }"
                        name="Created By"
                        rules="required"
                      >
                        <b-form-input
                          v-model="data.created_by"
                          id="created_by"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Created By"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Location" label-for="location">
                      <validation-provider
                        #default="{ errors }"
                        name="Location"
                        rules="required"
                      >
                        <b-form-input
                          v-model="data.location"
                          id="location"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Location"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Upload Image" label-for="image">
                      <b-form-file
                        ref="file-input"
                        accept="image/*"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        @change="setFileImage"
                      />
                    </b-form-group>
                    <div v-if="data.thumbnail" style="position: relative" class="w-100">
                      <b-img
                        :src="data.thumbnail"
                        rounded
                        alt="Rounded image"
                        class="d-inline-block mr-1 mb-1 w-100"
                      />
                      <div
                        style="position: absolute; top: 0.45rem; right: 0.5rem"
                        @click="resetImage"
                      >
                        <b-button
                          variant="gradient-danger"
                          class="btn-icon rounded-circle"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="12" class="mt-3">
                    <b-button variant="primary" type="submit" @click.prevent="submit">Submit</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      static
      v-model="modal_crop_image"
      id="crop-image"
      ref="cropimage"
      title="Crop Gambar"
      @hidden="resetCropImage"
      @ok="handleCropImage"
    >
      <vue-cropper
        ref="cropper"
        :src="data.file_raw"
        :crop-box-resizable="false"
        :toggle-drag-mode-on-dblclick="false"
      />
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-1"
        @click="setCropArea"
      >
        Set Default Crop
      </b-button>
    </b-modal>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody,
    BFormGroup, BFormInput, BFormTextarea, BFormFile, 
    BImg, BButton, BForm, BModal
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import flatPickr from 'vue-flatpickr-component'

  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'

  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import { quillEditor } from 'vue-quill-editor'
  
  import VueCropper from "vue-cropperjs";
  import "cropperjs/dist/cropper.css";

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BFormGroup, BFormInput, BFormTextarea, BFormFile, 
      BImg, BButton, BForm, BModal,
      flatPickr, quillEditor, ValidationProvider, ValidationObserver, VueCropper
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        data: {
          name: null,
          start_date: null,
          end_date: null,
          description: null,
          created_by: null,
          location: null,
          thumbnail: null,
          file_raw: null,
          file: {
            url: null,
            image: null,
          }
        },
        active_role: null,
        modal_crop_image: false
      }
    },
    created() {
      this.active_role = localStorage.getItem('role')
      this.loadDataEvent()
      this.checkAuth()
    },
    methods: {
      setFileImage(event) {
        const file = event.target.files[0];

        if (file.type.indexOf("image/") === -1) {
          this.$refs["file-input"].reset();
          this.createToast('danger', 'Edit Event', 'Please select an image file');
          return;
        }

        if (typeof FileReader === "function") {
          const reader = new FileReader();

          reader.onload = (event) => {
            let size_file = Math.floor(file.size / 1024);

            if (size_file <= 1024) {
              this.data.file_raw = event.target.result;
              
              this.showModalCrop();

              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(event.target.result);
            } else {
              this.$refs["file-input"].reset();
              this.createToast('danger', 'Edit Event', 'Ukuran file lebih dari 1 MB');
            }
          };
          reader.readAsDataURL(file);
        } else {
          this.$refs["file-input"].reset();
          this.createToast('danger', 'Edit Event', 'Sorry, FileReader API not supported');
        }
      },
      showModalCrop() {
        this.modal_crop_image = true;
      },
      setCropArea() {
        this.$refs.cropper.setCropBoxData({
          width: 415,
          height: 287,
        });
      },
      resetCropImage() {
        if (!this.data.file_raw) {
          this.$refs["file-input"].reset();
        }
      },
      handleCropImage() {
        this.data.file.url = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.data.thumbnail = this.data.file.url

        let block = this.data.file.url.split(";");
        let content_type = block[0].split(":")[1];
        let real_data = block[1].split(",")[1];
        this.data.file.image = this.b64toBlob(real_data, content_type);
      },
      resetImage() {
        this.$refs["file-input"].reset();

        this.data.thumbnail = null
        this.data.file.image = null;
        this.data.file.url = null;
        this.data.file_raw = null;
      },

      async loadDataEvent() {
        let cid = localStorage.getItem('client_id')
        let id = this.$route.params.id
				
				let dataAPI = null
        if(this.active_role == 'corporate') {
          const config = {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          }
          dataAPI = await this.$axios.get(`${cid}/event/${id}`, config)
        }

        let event = dataAPI.data.data
        let data = this.data

        data.name = event.name
        data.start_date = event.start_date_raw
        data.end_date = event.end_date_raw
        data.description = event.description
        data.created_by = event.created_by
        data.location = event.location
        data.thumbnail = event.image
      },
      submit() {
        this.$refs.eventRules.validate().then(success => {
          if(success) {
            let start_date = new Date(this.data.start_date)
            let end_date = new Date(this.data.end_date)

            if(start_date > end_date) {
              this.createToast('danger', 'Edit Event', 'Tanggal selesai tidak boleh kurang dari tanggal saat ini')
            } else {
              let cid = localStorage.getItem('client_id')
              let id = this.$route.params.id
              
              const data = new FormData();

              data.append("name", this.data.name)
              data.append("description", this.data.description)
              data.append("image", this.data.file.image)
              data.append("start_date", this.data.start_date)
              data.append("end_date", this.data.end_date)
              data.append("created_by", this.data.created_by)
              data.append("location", this.data.location)

              const config = {
                headers: {
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
              }

              this.$axios.post(`${cid}/event/${id}?_method=PUT`, data, config)
                .then(res => {
                  this.createToast('success', 'Edit Event', 'Edit event success')

                  setTimeout(() => {
                    this.$router.push({ path: '/event' })
                  }, 3000);
                })
                .catch(error => {
                  console.log(error)
                })
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .ql-container {
    min-height: inherit;
  }
</style>